import {loadStripe, Stripe, StripeCardElement} from '@stripe/stripe-js'
import {isRight} from 'fp-ts/lib/These'
import styled from 'styled-components'
import {
  Box,
  Button,
  FormField,
  Grommet,
  Heading,
  InfiniteScroll,
  Layer,
  Meter,
  ResponsiveContext,
  Spinner,
  Text,
  TextInput,
  Tip
} from 'grommet'
import {Add, CircleInformation, Close, Currency} from 'grommet-icons'
import {grommet} from 'grommet/themes'
import {deepMerge} from 'grommet/utils'
import React, {useContext, useEffect, useRef, useState} from 'react'
import useWebSocket from 'react-use-websocket'
import {getConfig} from './common/get-config'
import {List, ListMessage} from './common/types'
import {useQueryString} from './hooks/user-query-string'
import {createPayment, style as stripeStyle} from './stripe'

const API_WS = getConfig('REACT_APP_API_WS')
const STRIPE_KEY = getConfig('REACT_APP_STRIPE_PUBLIC_KEY')
const amountRegex = new RegExp(/^([0-9]+)?(\.([0-9]{0,2})?)?$/)
const maxNameLength = 140

const listTheme = deepMerge(grommet, {
  global: {
    font: {
      family: 'neue-haas-unica',
      size: '25px'
    }
  }
})

function App() {
  const {lastJsonMessage} = useWebSocket(API_WS)
  const [list, setList] = useState<List>([])
  const [ready, setReady] = useState(false)
  const [updatesPaused, setUpdatesPaused] = useState(false)
  const [form, setForm] = useState<boolean>(false)
  const cardElement = useRef<HTMLDivElement | null>(null)
  const stripeRef = useRef<Stripe | undefined>()
  const cardRef = useRef<StripeCardElement | undefined>()
  const [amount, setAmount] = useState<string>()
  const [name, setName] = useState<string>('')
  const [entityId, setEntityId] = useState<number>()
  const [loading, setLoading] = useState(false)
  const [cardValid, setCardValid] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [show, setShow] = useQueryString<string>('position', '0')
  const size = useContext(ResponsiveContext)

  useEffect(() => {
    if (lastJsonMessage) {
      switch (lastJsonMessage.type) {
        case 'list': {
          if (updatesPaused) {
            return
          }
          const decoded = ListMessage.decode(lastJsonMessage)
          if (isRight(decoded)) {
            setReady(true)
            setList(decoded.right.data)
          } else {
            console.warn('Invalid list message', lastJsonMessage)
          }
        }
      }
    }
  }, [lastJsonMessage, updatesPaused])

  useEffect(() => {
    if (form) {
      loadStripe(STRIPE_KEY).then(stripe => {
        if (!stripe) {
          console.error('Unable to load stripe')
          return
        }
        stripeRef.current = stripe
        if (!cardElement.current) {
          console.error('Unable to display stripe')
          return
        }
        const elements = stripe.elements()
        const card = elements.create('card', {style: stripeStyle})
        cardRef.current = card

        // Stripe injects an iframe into the DOM
        card.mount(cardElement.current)
        card.on('change', event => {
          // Disable the Pay button if there are no card details in the Element
          setCardValid(!event.empty)
          if (event.error) {
            setError(event.error.message)
          }
        })
      })
    }
  }, [form])

  const onSubmit = async () => {
    if (!amount || !stripeRef.current || !cardRef.current) {
      // console.log('returning ', amount, stripeRef.current, cardRef.current)
      return
    }
    setError(undefined)
    setLoading(true)
    const stripe = stripeRef.current
    const card = cardRef.current
    const res = await createPayment(Number(amount), entityId, name)
    const {clientSecret, optimisticList} = res
    if (!clientSecret) {
      setError('Unable to create payment')
      setLoading(false)
      return
    }
    if (optimisticList) {
      setList(optimisticList)
      setUpdatesPaused(true)
      setTimeout(() => setUpdatesPaused(false), 5000)

      const position = optimisticList.findIndex(
        i => i.entityId === res.entityId
      )

      if (position) {
        setTimeout(() => {
          setShow(String(position))
        }, 10)
      }
    }

    await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card
        }
      })
      .then(function (result) {
        if (result.error) {
          // Show error to your customer
          setError(result.error.message)
        } else {
          // The payment succeeded!
          setError(undefined)
          setForm(false)
          // console.log('Payment good', result.paymentIntent.id)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const valid = cardValid && !!amount && !!name && name.length <= maxNameLength

  return (
    <Grommet style={{height: '100%', width: '100%'}} theme={listTheme}>
      <Wrapper>
        {ready ? (
          <ListWrapper>
            <InfiniteScroll
              items={list}
              show={parseInt(show) || undefined}
              key={show}
              replace={false}
              step={100}
            >
              {(item: List[0], index: number) => (
                <Box
                  flex={false}
                  key={item.name}
                  pad="medium"
                  fill="horizontal"
                  border={{side: 'bottom'}}
                  align="center"
                  alignContent="center"
                  // justify="start"
                  direction="row"
                  gap="large"
                  onClick={() => {
                    setForm(true)
                    setEntityId(item.entityId)
                    setName(item.name)
                    setShow(String(index))
                  }}
                >
                  <Box width={size === 'small' ? '15%' : '10%'}>
                    <Text size="medium">{index + 1}.</Text>
                  </Box>
                  <Box>
                    <Text size="medium">{item.name}</Text>
                  </Box>
                </Box>
              )}
            </InfiniteScroll>
          </ListWrapper>
        ) : (
          <Box fill={true} justify="center" align="center">
            <Spinner size="large" />
          </Box>
        )}
        <Footer>
          <Heading
            level={3}
            size="medium"
            style={{
              fontFamily: 'neue-haas-grotesk-display',
              width: '60px'
            }}
          >
            the&nbsp;list.
          </Heading>
          <Button
            size="medium"
            label="Add"
            onClick={() => setForm(true)}
            primary
            color="light-2"
          />
          <div style={{width: '60px', textAlign: 'right'}}>
            <Tip
              plain={true}
              content={
                <TipContent>
                  <p>
                    Add a new item or vote up an existing item. The more you
                    spend the higher it goes.
                  </p>
                </TipContent>
              }
            >
              <CircleInformation color="light-5" />
            </Tip>
          </div>
        </Footer>
      </Wrapper>
      {form && (
        <Layer
          position="right"
          full="vertical"
          modal
          onClickOutside={() => setForm(false)}
          onEsc={() => setForm(false)}
        >
          <FormWrapper
            onSubmit={e => {
              e.preventDefault()
              onSubmit()
            }}
          >
            <FormHeader>
              <Heading level={2} margin="none">
                {entityId ? 'Boost it' : 'Add to the list'}
              </Heading>
              <Button
                icon={<Close />}
                style={{padding: '0px'}}
                onClick={() => setForm(false)}
              />
            </FormHeader>
            <Box flex="grow" overflow="auto" pad={{vertical: 'medium'}}>
              <FormField
                label="Title"
                htmlFor="name"
                help={`${
                  name !== '' ? 'This will' : 'What should'
                } appear on the list`}
                error={
                  name.length > maxNameLength
                    ? `${maxNameLength} Characters max`
                    : undefined
                }
              >
                <TextInput
                  id="name"
                  type="text"
                  // placeholder="Value to appear in list"
                  icon={
                    <Meter
                      type="circle"
                      max={maxNameLength}
                      size="30px"
                      thickness="5px"
                      background="light-2"
                      values={[
                        {
                          value: name.length,
                          color: name.length > 120 ? 'accent-2' : 'accent-1'
                        }
                      ]}
                    />
                  }
                  reverse
                  maxLength={180}
                  value={name}
                  onChange={e => {
                    setEntityId(undefined)
                    setName(e.target.value)
                  }}
                />
              </FormField>
              <FormField
                label="Amount"
                htmlFor="amount"
                help="The more you spend the higher it goes"
                info={
                  <Box direction="row" justify="end">
                    <Text size="10px">Payments are in US Dollars</Text>{' '}
                  </Box>
                }
              >
                <TextInput
                  id="amount"
                  icon={<Currency />}
                  type="text"
                  placeholder="0.00"
                  value={amount || ''}
                  onChange={e => {
                    const value = e.target.value.replace(/[^0-9.]/g, '')
                    if (value === '' || amountRegex.test(value)) {
                      setAmount(value)
                    }
                  }}
                />
              </FormField>

              <FormField error={error} label="Card details">
                <div
                  style={{marginTop: '20px'}}
                  ref={el => (cardElement.current = el)}
                ></div>
              </FormField>
            </Box>
            <Box flex={false} align="center" pad="large">
              <Button
                fill="horizontal"
                primary
                type="submit"
                label="Get on it"
                disabled={!valid || loading}
              />
            </Box>
          </FormWrapper>
        </Layer>
      )}
    </Grommet>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ListWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
  margin-bottom: 73px;
`

const TipContent = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
`

const FormWrapper = styled.form`
  margin: 20px;
  overflow: auto;
`

const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 10px 24px;
  align-items: center;
  background-color: hsl(0, 0%, 98%);
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 73px;
`

export default App
