import {useState, useCallback, useEffect} from 'react'

export const useQueryString = <T extends string = string>(
  key: string,
  initialValue: T
): [string | T, (v: T) => void] => {
  const [value, setValue] = useState(() => {
    const sp = new URLSearchParams(document.location.search)
    return sp.get(key) || initialValue
  })
  const onSetValue = useCallback(
    (newValue: T) => {
      setValue(newValue)
      const sp = new URLSearchParams(document.location.search)
      sp.set(key, newValue)
      window.history.pushState(null, '', `${document.location.pathname}?${sp}`)
    },
    [key]
  )

  useEffect(() => {
    const onPop = () => {
      const sp = new URLSearchParams(document.location.search)
      setValue(sp.get(key) || initialValue)
    }
    window.addEventListener('popstate', onPop)
    return () => window.removeEventListener('popstate', onPop)
  }, [initialValue, key])

  return [value, onSetValue]
}
