import {getConfig} from './common/get-config'
import * as t from 'io-ts'
import {isLeft} from 'fp-ts/lib/These'
import {ListCodec} from './common/types'

const API_URL = getConfig('REACT_APP_API_URL')

const PaymentIntentResponseCodec = t.partial({
  clientSecret: t.string,
  optimisticList: ListCodec,
  entityId: t.number
})

export async function createPayment(
  amount: number,
  entityId?: number,
  name?: string
) {
  // console.log('creating payment')
  const res = await fetch(`${API_URL}/create-payment-intent`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({amount, entityId, name})
  })
  const json = await res.json()
  // console.log({res, json})
  const decoded = PaymentIntentResponseCodec.decode(json)
  if (isLeft(decoded)) {
    throw new Error('Invalid response')
  }
  return decoded.right
}

export const style = {
  base: {
    color: '#32325d',
    fontFamily: 'Arial, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#32325d'
    }
  },
  invalid: {
    fontFamily: 'Arial, sans-serif',
    color: '#fa755a',
    iconColor: '#fa755a'
  }
}
