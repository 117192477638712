export function getConfig(name: string, defaultValue?: string) {
  const value = process.env[name];
  if (value !== undefined && value !== null) {
    return value;
  } else {
    if (defaultValue === undefined || defaultValue === null) {
      throw new Error(`${name} required`);
    }
    return defaultValue;
  }
}
