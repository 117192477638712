import * as t from 'io-ts'

export const ListItemCodec = t.type({
  entityId: t.number,
  name: t.string
})

export const ListItemWithTotalsCodec = t.intersection([
  ListItemCodec,
  t.type({
    total: t.number
  })
])

export const ListCodec = t.array(ListItemCodec)
export type List = t.TypeOf<typeof ListCodec>

export const ListWithTotalsCodec = t.array(ListItemWithTotalsCodec)
export type ListWithTotals = t.TypeOf<typeof ListWithTotalsCodec>

export const AddValueCodec = t.type({
  entityId: t.string,
  amount: t.number
})

export type AddValue = t.TypeOf<typeof AddValueCodec>

export const ListMessage = t.type({
  type: t.literal('list'),
  data: ListCodec
})
